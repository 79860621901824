import React from 'react';
import '../styles/component_styles/HomeStyle.css';
import me from '../images/me.jpg'
        // <ul>
        //   <li>
        //     <p>BTC:</p>
        //     <p>xxxxxxxxxxxx</p>
        //   </li>
        //   <li>
        //     <p>ETH:</p>
        //     <p>xxxxxxxxxxxx</p>
        //   </li>
        //   <li>
        //     <p>XMR:</p>
        //     <p>xxxxxxxxxxxx</p>
        //   </li>
        // </ul>


class Home extends React.Component {
  render() {
    return (<div>
    	<header className="App-header">   
        <p className="name">Glen Vollmer</p>
        
        <a href="" 
           target="_blank">
          <div className="imgDiv">
            <img src={me} className="me" alt="logo" />
          </div>
        </a>

        <p><a href="https://github.com/glenvollmer" target="_blank">Software Developer</a>, <a href="https://soundcloud.com/glen-vollmer" target="_blank">Artist</a>, <a href="https://www.amazon.com/hz/wishlist/ls/25BKNES40OUJU?ref_=wl_share" target="_blank">Book Worm</a></p>
      </header>
      
      <footer>
        <h1>(☭ ͜ʖ ☭)</h1>

      </footer>
      </div>);
  }
}

export {Home};