import React from 'react';
import './App.css';

import { Home } from './components/Home.js';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


console.log("\n\n\n??? y u peepin ???\n\n\n");
function App() {
  return (
    <Router>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
    </Router>


  );
}

export default App;
